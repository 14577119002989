import { RtmClient } from "@zexcore/rtm-client";
import { AppConfig } from "../config";
import { GetCurrentToken } from "../firebase";
import { ChatMessage, Meeting } from "@zexware/meetup-types";
let client: RtmClient | undefined;

export async function ensureAuthentication(force?: boolean) {
  return new Promise(async (resolve, reject) => {
    if (!client || force) {
      if (client) {
        client.Close();
      }
      const token = await GetCurrentToken();
      if (!token) {
        resolve(false);
        return;
      }
      client = new RtmClient(AppConfig.rtm, {
        reconnectDelayMs: 5000,
        onReconnecting(attempt) {
          console.log(`Trying to reconnect for ${attempt} time...`);
        },
        authenticationData: token,
        async onOpen() {
          console.log("Connected to the RTM server. ");
          resolve(true);
        },

        onClose() {
          console.log("Connection with the RTM server was closed.");
        },

        onError() {
          console.log("Error connecting to the RTM server");
          reject();
        },
      });
    } else {
      resolve(true);
    }
  });
}

export async function disconnectRtm() {
  if (client) {
    client.Close();
    client = undefined;
  }
}

export function Subscribe(
  event: string,
  callback: (...data: any[]) => void | Promise<void>
) {
  return client?.Subscribe(event, callback);
}

export async function rtmCreateMeeting() {
  await ensureAuthentication();
  const resp = await client?.CallWait("rtmCreateMeeting");
  return resp as Meeting;
}

export async function rtmJoinMeeting(code: string) {
  await ensureAuthentication();
  const resp = await client?.CallWait("rtmJoinMeeting", { code });
  return resp as { token: string; meeting: Meeting };
}

/**
 * Returns a new stream channel and token, or, an existing random one.
 */
export async function rtmGetUserInfo(uid: string) {
  await ensureAuthentication();
  const resp = await client?.CallWait<string>("rtmGetUserInfo", { uid });
  return resp;
}

/**
 * Returns a new stream channel and token, or, an existing random one.
 */
export async function rtmSendMessage(msg: Partial<ChatMessage>) {
  await ensureAuthentication();
  const resp = await client?.CallWait<ChatMessage>("rtmSendMessage", msg);
  return resp;
}
