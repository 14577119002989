import React, { useEffect, useState } from "react";
import { Button, Stack, useMediaQuery } from "@mui/material";
import LinkButton from "./Buttons";
import { IMG_LOGO } from "../assets/brand";

export default function StaticHeader(props: { bg?: string }) {
  const desktop = useMediaQuery("screen and (min-width: 710px)");
  const [stick, setStick] = useState(false);

  function handleScroll() {
    setStick(window.scrollY > 400);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Stack
      sx={{
        bgcolor: props.bg,
        zIndex: 100,
        transition: "all .7s",
        top: 0,
        left: 0,
        right: 0,
        position: stick ? "fixed" : "fixed",
        background: stick ? "#FFF2" : "#FFF0",
        backdropFilter: "blur(4px)",
        alignItems: "center",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          width: "100%",
          maxWidth: "1280px",
          padding: desktop ? "24px 0" : "5px 10px 0 10px",
        }}
      >
        {/* The logo  */}
        <img
          onClick={() => window.open("https://zexware.com")}
          src={IMG_LOGO}
          alt=""
          style={{ height: "64px", objectFit: "contain", cursor: "pointer" }}
        />

        {/* The navigation menu  */}
        {desktop && (
          <nav>
            <LinkButton label="Home" href="https://zexware.com/#" />

            <LinkButton
              observable="services"
              label="Services"
              href="https://zexware.com/#services"
            />

            <LinkButton
              href="https://zexware.com/#integrations"
              label="Integrations"
            />

            <LinkButton
              href="https://zexware.com/#testimonials"
              label="Testimonials"
            />

            <LinkButton href="https://zexware.com/#products" label="Products" />

            <LinkButton href="https://zexware.com/#team" label="Team" />

            <LinkButton href="https://zexware.com/#contact" label="Contact" />
          </nav>
        )}

        {/* The contact button  */}
        <Stack>
          <Button
            href="https://store.zexware.com/billing"
            variant="outlined"
            sx={{
              color: "white",
              background: "var(--alpha-black-5, rgba(47, 43, 67, 0.05))",
              borderRadius: "12px",
              padding: "8px 16px",
              borderColor: "#2563EB",
              ":hover": { color: "#4488FF" },
            }}
          >
            Customer Portal
          </Button>
        </Stack>
      </Stack>
      {/* The navigation menu  */}
      {!desktop && (
        <nav
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <LinkButton label="Home" href="https://zexware.com/#" />

          <LinkButton
            observable="services"
            label="Services"
            href="https://zexware.com/#services"
          />

          <LinkButton
            href="https://zexware.com/#integrations"
            label="Integrations"
          />

          <LinkButton
            href="https://zexware.com/#testimonials"
            label="Testimonials"
          />

          <LinkButton href="https://zexware.com/#products" label="Products" />

          <LinkButton href="https://zexware.com/#team" label="Team" />

          <LinkButton href="https://zexware.com/#contact" label="Contact" />
        </nav>
      )}
    </Stack>
  );
}
