import React, { useEffect, useState } from "react";
import { Button, useTheme } from "@mui/material";

export default function LinkButton(props: {
  label?: string;
  href?: string;
  onClick?: any;
  active?: boolean;
  observable?: string;
}) {
  const theme = useTheme();
  const [active, setActive] = useState(props.active);

  useEffect(() => {
    if (props.observable) {
      const el = document.getElementById(props.observable);
      if (!el) return;
      const observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // Set active state.
            setActive(true);
            return;
          } else {
            // Set inactive state.
            setActive(false);
          }
        },
        {
          root: null,
          threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        }
      );

      observer.observe(el!);
    }
  }, []);
  return (
    <Button
      onClick={props.onClick}
      sx={{
        color: active ? theme.palette.primary.light : "white",
        ":hover": {
          background: "transparent",
          color: theme.palette.primary.light,
        },
      }}
      variant="text"
      disableElevation
      disableRipple
      disableTouchRipple
      href={props.href}
    >
      {props.label}
    </Button>
  );
}
