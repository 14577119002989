import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { SnackbarProvider } from "notistack";
import { RecoilRoot } from "recoil";
import ModalProvider from "mui-modal-provider";
import ThemeManager from "./utils/ThemeManager";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import { Stack } from "@mui/material";
import { Route, Routes } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import PageLogin from "./pages";
import PageMeeting from "./pages/meeting";
import AuthProvider from "./utils/AuthenticatedRoute";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RecoilRoot>
    <ThemeManager>
      <AgoraRTCProvider
        client={AgoraRTC.createClient({
          codec: "vp8",
          mode: "rtc",
          role: "host",
        })}
      >
        <ModalProvider>
          <SnackbarProvider
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
          >
            <Stack
              sx={{
                minHeight: "100vh",
                maxWidth: "100vw",
                bgcolor: "#030712",
                overflow: "auto",
              }}
            >
              <Router>
                <Routes>
                  <Route
                    path="/meeting/:id"
                    element={
                      <AuthProvider loading fallback={<PageLogin />}>
                        <PageMeeting />
                      </AuthProvider>
                    }
                  />
                  <Route path="/*" element={<PageLogin />} />
                </Routes>
              </Router>
            </Stack>
          </SnackbarProvider>
        </ModalProvider>
      </AgoraRTCProvider>
    </ThemeManager>
  </RecoilRoot>
);
