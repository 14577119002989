import React, { useEffect, useState } from "react";
import { FbAuth } from "../firebase";
import { disconnectRtm, ensureAuthentication } from "../api";
import { signInAnonymously } from "firebase/auth";

/**
 * Renders children only if the user is logged in. Otherwise navigates to the /login (or specified redirectTo) page.
 * @returns
 */
export default function AuthProvider(props: {
  children: any;
  fallback: any;
  loading: any;
}) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const sub = FbAuth.onAuthStateChanged(async (state) => {
      if (state && state.uid) {
        // Connect to the RTM
        await ensureAuthentication();
      } else {
        await disconnectRtm();
      }
      setReady(true);
    });
    return sub;
  }, []);

  // If not ready, we render loading
  if (!ready) {
    return props.loading;
  }

  // If no profile, we return fallback
  if (!FbAuth.currentUser) {
    return props.fallback;
  }

  // we return children (not kids)
  return props.children;
}
