import { useEffect, useState } from "react";

export default function useInterval(delayMs: number, cb?: () => void) {
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now());
      cb?.();
    }, delayMs);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return [time];
}
