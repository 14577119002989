import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import AgoraRTC, {
  LocalUser,
  RemoteUser,
  useClientEvent,
  useCurrentUID,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  useRTCClient,
  useRemoteUsers,
} from "agora-rtc-react";
import { AppConfig } from "../config";
import {
  Fullscreen,
  Mic,
  MicOff,
  ScreenShare,
  StopScreenShare,
  Videocam,
  VideocamOff,
} from "@mui/icons-material";
import useInterval from "../utils/Interval";
import { Player } from "@lottiefiles/react-lottie-player";
import { FbAuth } from "../firebase";
import { deleteUser, signOut } from "firebase/auth";
import { useNavigate, useParams } from "react-router";
import { rtmGetUserInfo, rtmJoinMeeting } from "../api";
import { LOGO_LIGHT } from "../assets/brand";
import ZWInput from "../components/ZWInput";
import { IC_COPY, IC_USER_ADD } from "../assets/ui";
import { AnimatePresence, motion } from "framer-motion";

export default function PageMeeting() {
  const route = useParams();
  const nav = useNavigate();
  const [camera, setCamera] = useState(true);
  const [mic, setMic] = useState(true);
  const [shareScreen, setShareScreen] = useState(false);

  const [requiresPermissions, setRequiresPermissions] = useState(false);
  const client = useRTCClient();

  const localMic = useLocalMicrophoneTrack(mic);
  const localVid = useLocalCameraTrack(camera, {
    optimizationMode: "detail",
    encoderConfig: { frameRate: 30, height: 1280, width: 720 },
  });
  const localScreen = useRef<any>(null);
  const remoteUsers = useRemoteUsers();
  const uid = useCurrentUID();
  const [busy, setBusy] = useState(false);
  const [showInviteOptions, setShowInviteOptions] = useState(false);
  const [time] = useInterval(2000);
  const [forceRefresh, setForceRefresh] = useState(0);
  const remoteUserInfoes = useRef<{ uid: string; name: string }[]>([]);
  // The UID of the focused user. A focused user is centered in the main view.
  // Similar to the Google Meet's Pinned feature.
  // By default, the first joining user is focused.
  const [focusedUser, setFocusedUser] = useState<any>();

  useClientEvent(client, "user-joined", async (user) => {
    if (
      localMic.localMicrophoneTrack &&
      localMic.localMicrophoneTrack.enabled
    ) {
      await client.publish([localMic.localMicrophoneTrack]);
      await localMic.localMicrophoneTrack.setMuted(false);
    }
    if (
      localVid.localCameraTrack &&
      localVid.localCameraTrack.enabled &&
      !shareScreen // Make sure screen sharing is disable before sharing video
    ) {
      await client.publish([localVid.localCameraTrack]);
      await localVid.localCameraTrack.setMuted(false);
    }
    if (localScreen.current && shareScreen) {
      await client.publish(localScreen.current);
    }

    addUserInfo(user.uid.toString());

    if (!focusedUser) {
      setFocusedUser(user.uid);
    }
    setForceRefresh(new Date().getTime());
  });

  useClientEvent(client, "user-left", (user) => {
    const ui = remoteUserInfoes.current.find((u) => u.uid === user.uid);
    enqueueSnackbar(`User ${ui?.name} left the call.`, {
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
    remoteUserInfoes.current = [
      ...remoteUserInfoes.current.filter((u) => u.uid === user.uid),
    ];
    setForceRefresh(new Date().getTime());
  });

  useClientEvent(client, "user-published", async (user, mediaType) => {
    await client.subscribe(user, mediaType);
  });

  useClientEvent(client, "user-unpublished", (user, mediaType) => {
    client.unsubscribe(user, mediaType);
  });

  async function addUserInfo(uid: string) {
    try {
      const ui = await rtmGetUserInfo(uid);
      if (ui) {
        remoteUserInfoes.current = [
          ...remoteUserInfoes.current,
          { name: ui, uid: uid },
        ];
      }
    } catch (err: any) {
      console.error("Error adding user info. ", err);
    }
  }

  async function toggleCamera() {
    // If enabled, we publish
    if (!shareScreen) {
      // Publish
      await localVid.localCameraTrack?.setEnabled(!camera);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (localVid.localCameraTrack?.enabled) {
        await client.publish([localVid.localCameraTrack!]);
      }
      setCamera(!camera);
    }
  }
  async function toggleMic() {
    setMic(!mic);
    localMic.localMicrophoneTrack?.setEnabled(!mic);
  }

  async function toggleScreen() {
    if (localScreen.current && shareScreen) {
      // Disable
      client.unpublish(localScreen.current);
      if (localScreen.current.close) {
        localScreen.current.close?.();
      } else if (localScreen.current?.[1].close) {
        localScreen.current[1].close();
      }

      setShareScreen(false);
    } else {
      try {
        // Share screen
        const track = await AgoraRTC.createScreenVideoTrack(
          { displaySurface: "browser" },
          "auto"
        );
        if (track) {
          // Unpublish video track
          if (localVid.localCameraTrack?.enabled) {
            await client.unpublish(localVid.localCameraTrack);
          }
          // Public screen track
          await client.publish(track);
          localScreen.current = track;
          setShareScreen(true);
        }
      } catch (err: any) {
        console.error("Error sharing screen. ", err);
      }
    }
  }

  async function join() {
    try {
      const mics = await AgoraRTC.getMicrophones(false).catch();
      const vids = await AgoraRTC.getCameras(false).catch();

      if (!mics || !vids || mics?.length <= 0 || vids?.length <= 0) {
        setRequiresPermissions(true);
        return;
      }
      setBusy(true);

      // Get the token and meeting info using code
      const joinInfo = await rtmJoinMeeting(route.id!);
      if (!joinInfo) {
        enqueueSnackbar("Invalid meeting code. Try again. ", {
          variant: "error",
        });
        nav("/");
        return;
      }

      // // Join the call
      await client.join(
        AppConfig.agora.appId,
        joinInfo?.meeting.code!,
        joinInfo?.token!,
        FbAuth.currentUser?.uid!
      );
    } catch (err: any) {
      console.log("ERROR Loading Data. ERR: " + err);
      nav("/");
    }
    setBusy(false);
  }

  async function logout() {
    localMic.localMicrophoneTrack?.setEnabled(false);
    localVid.localCameraTrack?.setEnabled(false);
    client.leave();

    deleteUser(FbAuth.currentUser!);
    signOut(FbAuth);
    nav("/");
  }

  useEffect(() => {
    if (!route.id) {
      nav("/");
      return;
    }
    if (client.channelName) {
      return;
    }
    join();
  }, []);

  useEffect(() => {
    setForceRefresh(new Date().getTime());
  }, [focusedUser]);

  return (
    <Stack
      key={forceRefresh}
      flex={1}
      sx={{
        position: "relative",
        maxWidth: "1280px",
        width: "100%",
        alignSelf: "center",
      }}
    >
      {/* Header  */}
      <Stack
        direction={"row"}
        sx={{ mt: "32px", zIndex: 99 }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={"12px"}>
          <img
            src={LOGO_LIGHT}
            style={{ height: "64px", objectFit: "contain" }}
          />
          <Button
            variant="contained"
            onClick={() => setShowInviteOptions(true)}
            endIcon={
              <SvgIcon>
                <IC_USER_ADD />
              </SvgIcon>
            }
          >
            Invite Participants
          </Button>
        </Stack>

        <Stack direction={"row"} spacing={"12px"}>
          {/* The call management buttons  */}
          <Stack
            direction={"row"}
            alignItems={"center"}
            key={time}
            spacing={"8px"}
          >
            <IconButton
              color={!shareScreen ? "error" : "primary"}
              onClick={toggleScreen}
              sx={{
                background: !shareScreen ? "#F322" : "#FFF2",
              }}
            >
              {!shareScreen && <StopScreenShare />}
              {shareScreen && <ScreenShare />}
            </IconButton>
            <IconButton
              color={!camera ? "error" : "primary"}
              onClick={toggleCamera}
              sx={{
                background: !camera ? "#F322" : "#FFF2",
              }}
            >
              {!camera && <VideocamOff />}
              {camera && <Videocam />}
            </IconButton>
            <IconButton
              color={!mic ? "error" : "primary"}
              onClick={toggleMic}
              sx={{
                background: !mic ? "#F322" : "#FFF2",
              }}
            >
              {!mic && <MicOff />}
              {mic && <Mic />}
            </IconButton>
          </Stack>
          <Divider orientation="vertical" sx={{ height: "40px" }} />
          {/* Current user info with signout option */}
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={"8px"}
          >
            <Typography>
              Signed in as {FbAuth.currentUser?.displayName}
            </Typography>
            <Button color="warning" onClick={logout} variant="contained">
              Leave
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {/* Invite / Meeting Link Dialog  */}
      <AnimatePresence>
        {showInviteOptions ? (
          <motion.div
            style={{
              position: "fixed",
              borderRadius: "24px",
              background: "#555",
              width: "500px",
              alignSelf: "center",
              zIndex: 100,
            }}
            initial={{ top: -200 }}
            whileInView={{ top: 20 }}
            exit={{ top: -500 }}
            transition={{ duration: 0.4 }}
          >
            <Stack
              sx={{
                px: "24px",
                py: "12px",
              }}
              spacing={"8px"}
            >
              <Typography fontSize={24}>Invite Participants</Typography>
              <ZWInput
                label="Meeting Link"
                text={"https://meet.zexware.com/meeting/" + route.id}
                buttonIcon={IC_COPY}
                buttonClick={() => {
                  navigator.clipboard.writeText(
                    "https://meet.zexware.com/meeting/" + route.id
                  );
                  enqueueSnackbar("Link copied to clipboard!");
                }}
              />
              <ZWInput
                label="Meeting Code"
                text={route.id}
                buttonIcon={IC_COPY}
                buttonClick={() => {
                  navigator.clipboard.writeText(route?.id!);
                  enqueueSnackbar("Code copied to clipboard!");
                }}
              />
              <Button
                variant="contained"
                sx={{ alignSelf: "start" }}
                onClick={() => setShowInviteOptions(false)}
              >
                OK
              </Button>
            </Stack>
          </motion.div>
        ) : null}
      </AnimatePresence>
      {/* Permissions Message  */}
      {requiresPermissions && (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            background: "#333",
            backdropFilter: "blur(20)",
            zIndex: 5,
          }}
        >
          <Typography fontSize={32}>Permissions Required</Typography>
          <Typography fontSize={18}>
            Please enable microphone and camera to continue.
          </Typography>
        </Stack>
      )}

      {/* Loading Screen, when no one has joined.  */}
      {remoteUsers.length <= 0 && (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: 0,
          }}
        >
          <Player
            autoplay
            loop
            src="https://lottie.host/a0818aa6-46ce-463d-bce6-75aeae1ebb34/mAGb0W7sxo.json"
            style={{ height: "150px" }}
          />
          <Typography>Waiting for participants...</Typography>
        </Stack>
      )}

      {/* The focused user, or pinned user.  */}
      <Stack flex={1}>
        <Grid
          item
          xs
          sx={{
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            padding: "24px",
          }}
        >
          <RemoteUser
            user={remoteUsers.find((f) => f.uid === focusedUser)}
            playVideo
            playAudio
            style={{
              objectFit: "contain",
              background: "#333",
              borderRadius: "24px",
              flex: 1,
            }}
          >
            <Stack sx={{ p: "12px", position: "relative" }}>
              <Typography
                sx={{
                  p: "2px",
                  px: "8px",
                  background: "#DDD",
                  borderRadius: "50px",
                  color: "black",
                  alignSelf: "flex-start",
                }}
              >
                {
                  remoteUserInfoes.current.find((c) => c.uid === focusedUser)
                    ?.name
                }
              </Typography>
            </Stack>
          </RemoteUser>
        </Grid>
      </Stack>

      {/* The horizontal list of extra remote users.  */}
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        direction={"row"}
        sx={{ height: "250px" }}
        spacing={"8px"}
      >
        <LocalUser
          autoPlay
          cameraOn
          videoTrack={
            shareScreen && localScreen.current
              ? (localScreen.current as any)[0] || (localScreen.current as any)
              : localVid.localCameraTrack
          }
          playAudio={Boolean(localScreen.current?.[1])}
          audioTrack={localScreen.current?.[1]}
          playVideo
          style={{
            zIndex: 1,
            borderRadius: "8px",
            objectFit: "contain",
            height: "200px",
            width: "200px",
            background: "#333",
          }}
          label="You"
        >
          <Stack sx={{ p: "12px" }}>
            <Typography
              sx={{
                p: "2px",
                px: "8px",
                background: "#DDD",
                borderRadius: "50px",
                color: "black",
                alignSelf: "flex-start",
              }}
            >
              {FbAuth.currentUser?.displayName}
            </Typography>
          </Stack>
        </LocalUser>
        {remoteUsers
          .filter((u) => u.uid !== focusedUser)
          .map((ru) => (
            <RemoteUser
              key={ru.uid}
              user={ru}
              playVideo
              playAudio
              style={{
                zIndex: 1,
                borderRadius: "8px",
                objectFit: "contain",
                height: "200px",
                width: "200px",
                background: "#333",
              }}
            >
              <Stack sx={{ p: "12px", position: "relative" }}>
                <IconButton
                  onClick={() => {
                    setFocusedUser(ru.uid);
                    setForceRefresh(new Date().getTime());
                  }}
                  sx={{ position: "absolute", top: "12px", right: "12px" }}
                >
                  <Fullscreen />
                </IconButton>
                <Typography
                  sx={{
                    p: "2px",
                    px: "8px",
                    background: "#DDD",
                    borderRadius: "50px",
                    color: "black",
                    alignSelf: "flex-start",
                  }}
                >
                  {remoteUserInfoes.current.find((c) => c.uid === ru.uid)?.name}
                </Typography>
              </Stack>
            </RemoteUser>
          ))}
      </Stack>
    </Stack>
  );
}
