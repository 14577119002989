const VERSION = "2024.3.31";
const BUILD = 4;

const debug = {
  version: VERSION,
  build: BUILD,
  api: "http://192.168.100.70:3001",
  rtm: "ws://192.168.100.70:3001",
  agora: {
    appId: "70031a6640b740a587dbd7ab7364d3d7",
  },
  firebase: {
    apiKey: "AIzaSyB90R-usaRrrml2t5mAG-2uJbHzVgFvl3w",
    authDomain: "imingle-app.firebaseapp.com",
    projectId: "imingle-app",
    storageBucket: "imingle-app.appspot.com",
    messagingSenderId: "485026768616",
    appId: "1:485026768616:web:e4332d54d7f83aa0cbcb5d",
  },
};

const prod = {
  version: VERSION,
  build: BUILD,
  api: "https://meet-api.zexware.com",
  rtm: "wss://meet-api.zexware.com",
  agora: {
    appId: "70031a6640b740a587dbd7ab7364d3d7",
  },
  firebase: {
    apiKey: "AIzaSyB90R-usaRrrml2t5mAG-2uJbHzVgFvl3w",
    authDomain: "imingle-app.firebaseapp.com",
    projectId: "imingle-app",
    storageBucket: "imingle-app.appspot.com",
    messagingSenderId: "485026768616",
    appId: "1:485026768616:web:e4332d54d7f83aa0cbcb5d",
  },
};

const DEBUG = process.env.REACT_APP_DEBUG === "true";

export const AppConfig = DEBUG ? debug : prod;
