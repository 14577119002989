import React from "react";
import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { Facebook, GitHub, Instagram, Twitter } from "@mui/icons-material";
import { IMG_LOGO } from "../assets/brand";
import LinkButton from "./Buttons";

export default function StaticFooter() {
  const desktop = useMediaQuery("screen and (min-width: 710px)");
  return (
    <Stack
      sx={{
        pt: "22px",
        background: "#111827",
        width: desktop ? "100%" : "100%",
        alignItems: "center",
        borderRadius: "24px 24px 0px 0px",
      }}
    >
      <Stack sx={{ maxWidth: desktop ? "1280px" : "auto" }}>
        <Stack>
          <Stack
            direction={desktop ? "row" : "column"}
            columnGap={desktop ? "220px" : "40px"}
            rowGap={desktop ? "0px" : "40px"}
            sx={{
              py: "40px",

              // background: "#111827",
              borderRadius: "48px 48px 0px 0px",
            }}
            alignItems={"center"}
            justifyContent={desktop ? "space-around" : "space-around"}
          >
            <img src={IMG_LOGO} />
            {desktop && (
              <nav>
                <LinkButton href="https://zexware.com/#" label="Home" />

                <LinkButton href="https://zexware.com/#team" label="Team" />

                <LinkButton
                  label="Contact"
                  href="https://zexware.com/#contact"
                />
              </nav>
            )}

            <Stack direction={"row"}>
              <IconButton href="https://facebook.com/zexwareofficial">
                <Facebook />
              </IconButton>
              <IconButton href="https://twitter.com/zexware">
                <Twitter />
              </IconButton>
              <IconButton href="https://github.com/zexware">
                <GitHub />
              </IconButton>
              <IconButton href="https://instagram.com/zexware">
                <Instagram />
              </IconButton>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              py: "10px",
              // background: "#111827"
            }}
            alignItems={"center"}
            justifyContent={"space-around"}
            spacing="12px"
          >
            <Typography>
              Copyright &copy; 2023. All rights reserved.{" "}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
