import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Player } from "@lottiefiles/react-lottie-player";
import ZWInput from "../components/ZWInput";
import { IC_UESR, IMG_HERO } from "../assets/ui";
import { LOGO_LIGHT } from "../assets/brand";
import DualHeading from "../components/DualHeading";
import { signInAnonymously, updateProfile } from "firebase/auth";
import { FbAuth } from "../firebase";
import { ensureAuthentication, rtmCreateMeeting } from "../api";
import StaticHeader from "../components/Header";
import StaticFooter from "../components/Footer";
import { useLocation, useNavigate, useParams } from "react-router";

export default function PageLogin() {
  const desktop = useMediaQuery("screen and (min-width: 1024px)");
  const route = useParams();
  const nav = useNavigate();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const theme = useTheme();

  async function signin() {
    // Signup
    await signInAnonymously(FbAuth);
    // Update user's name
    await updateProfile(FbAuth.currentUser!, { displayName: name });
  }

  async function startMeeting() {
    try {
      setBusy(true);
      await signin();
      // Create a new meeting.
      await ensureAuthentication();
      const meeting = await rtmCreateMeeting();
      // Join meeting
      nav("/meeting/" + meeting.code);
    } catch (err: any) {
      enqueueSnackbar("Error creating meeting. ", { variant: "error" });
      console.error(err);
    }
    setBusy(false);
  }

  async function joinMeeting() {
    try {
      setBusy(true);
      await signin();
      // Join meeting
      window.location.pathname = "/meeting/" + code;
    } catch (err: any) {
      enqueueSnackbar("Error creating meeting. ", { variant: "error" });
      console.error(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    console.log(route);
    setCode(route?.id || "");
  }, []);

  return (
    <Stack
      sx={{
        width: "100%",
        maxWidth: "1280px",
        minHeight: "100vh",
        alignSelf: "center",
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <StaticHeader />
      <Stack
        flex={1}
        sx={{ my: "148px" }}
        justifyContent={desktop ? "space-between" : "center"}
        alignItems={"center"}
        direction={desktop ? "row" : "column"}
        spacing={"12px"}
      >
        <Stack alignItems={"start"}>
          <img
            src={LOGO_LIGHT}
            style={{ height: "64px", objectFit: "contain" }}
          />
          <Stack spacing="12px">
            <Typography
              fontSize={"30px"}
              fontWeight={"600"}
              sx={{ color: "#F5F5F6" }}
            >
              Voice & Video Calls
            </Typography>
            <Typography sx={{ fontSize: "16px", color: "#94969C" }}>
              MeetUp provides secure, easy to use voice and video calls for
              everyone.
            </Typography>
          </Stack>

          <Stack sx={{ mt: "48px" }} spacing={"8px"}>
            <DualHeading
              sx={{ fontSize: 28 }}
              h2sx={{ color: "#48F" }}
              h1="Join or Start a "
              h2="Meeting"
            />
            <ZWInput
              text={name}
              onUpdate={(t) => setName(t)}
              label="Display Name"
              important
              sx={{ width: "300px" }}
              placeholder="Enter your display name"
              startIcon={IC_UESR}
              errorText={error === "name" ? "Please enter a display name." : ""}
            />
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={"32px"}
              sx={{ pt: "14px" }}
            >
              <Button
                onClick={startMeeting}
                disabled={busy}
                size="large"
                type="submit"
                sx={{ mt: "4px", height: "42px" }}
                variant="contained"
              >
                {busy && (
                  <Player
                    autoplay
                    loop
                    src="https://lottie.host/4c1c073a-93b5-4373-84d5-5af6feed4e53/DD5Yy0BUBe.json"
                    style={{ height: "100px" }}
                  />
                )}
                {!busy && "Start New Meeting"}
              </Button>
              <div
                style={{
                  height: "20px",
                  width: "3px",
                  background: "#FFFA",
                  borderRadius: "20px",
                }}
              />
              <Typography>Or join a meeting </Typography>
              <ZWInput
                text={code}
                onUpdate={(t) => setCode(t)}
                placeholder="Enter meeting code"
                sx={{ width: "300px" }}
                endAdornment={
                  <Button disabled={busy} onClick={joinMeeting}>
                    Join
                  </Button>
                }
              />
            </Stack>
          </Stack>
        </Stack>

        {desktop && (
          <img
            src={IMG_HERO}
            style={{ height: "320px", objectFit: "contain" }}
          />
        )}
      </Stack>
      <StaticFooter />
    </Stack>
  );
}
